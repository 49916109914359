.item-add-form {
    margin-top: 10px;
}

.item-add-form input {
    width: 76.4%;
    margin-right: 3px;
    transition: border-color .3s ease;
}

.item-add-form button {
    width: 23.6%;
}

.item-add-form input.error {
    border: 1.5px solid tomato;
}