.app-header {
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.app-header h1 {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.app-header h2 {
    font-size: 1.2rem;
    color: grey;
}
.search-input {
    width: auto;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin-right: 3px;
}

.todo-list .list-group-item {
    padding: .25rem .75rem;
}
.todo-list-item {
    font-size: 1.25rem;
}

.todo-list-item-label {
    margin-left: 1.25rem;
    line-height: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.todo-list-item button {
    width: 35px;
    margin: 3px;
}

.todo-list-item.important .todo-list-item-label {
    font-weight: bold;
    color: steelblue;
}

.todo-list-item.done .todo-list-item-label {
    text-decoration: line-through;
}
.item-add-form {
    margin-top: 10px;
}

.item-add-form input {
    width: 76.4%;
    margin-right: 3px;
    -webkit-transition: border-color .3s ease;
    transition: border-color .3s ease;
}

.item-add-form button {
    width: 23.6%;
}

.item-add-form input.error {
    border: 1.5px solid tomato;
}
.todo-app {
    margin: 2rem auto 0 auto;
    max-width: 400px;
}

.top-panel {
    margin: 1rem 0;
}
