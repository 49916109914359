.todo-list-item {
    font-size: 1.25rem;
}

.todo-list-item-label {
    margin-left: 1.25rem;
    line-height: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.todo-list-item button {
    width: 35px;
    margin: 3px;
}

.todo-list-item.important .todo-list-item-label {
    font-weight: bold;
    color: steelblue;
}

.todo-list-item.done .todo-list-item-label {
    text-decoration: line-through;
}